import { useState } from "react";

import Cards, { Focused } from "react-credit-cards";

import { GoChevronUp } from "react-icons/go";
import { RiUserLine } from "react-icons/ri";

import {
  CardAccordion,
  Container,
  ContainerBorder,
  ContainerCard,
  ContainerInput,
  DataDropDown,
  Form,
  FormCreditCard,
  FormFull,
  FormLabel,
} from "./styles";

import { Colors } from "constants/colors";

import { InputForm } from "components/Forms/Input";

import { Select as SelectComponent } from "components/Forms/Select";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import card_icon from "assets/card-icon.svg";

type CardFormData = {
  card_holder_name: string;
  card_number: string;
  card_expiration_date: string;
  card_cvv: string;
};

const cardFormSchema = yup.object().shape({
  card_number: yup.string().required("Numero Obrigatorio"),
  card_holder_name: yup.string().required("Nome obrigatório"),
  card_expiration_date: yup.string().required("Data obrigatória"),
  card_cvv: yup.string().required("Cvv obrigatório"),
});

export function PaymentWithTwoCreditCards() {
  const [focus, setFocus] = useState<Focused | undefined>("number");

  const { register, handleSubmit, formState, watch } = useForm({
    resolver: yupResolver(cardFormSchema),
    defaultValues: {
      card_holder_name: "",
      card_number: "",
      card_expiration_date: "",
      card_cvv: "",
      // card_cvv: 690,
    },
  });

  const handleInputFocus = (e: any): void => {
    setFocus(e);
  };

  return (
    <Container>
      <div className="container-card">
        <CardAccordion
          active
          // active={ticket.isOpen}
          // onClick={() => handleOpenTicketEvent(ticket.id)}
        >
          <div className="content">
            <div className="image">
              <img
                className="card"
                src={card_icon}
                alt="Logo"
                width={25}
                height={22.5}
              />
            </div>

            <div className="texts">
              <h2>Cartão #1</h2>
            </div>
          </div>

          <GoChevronUp size={16} color={Colors.secondary50} />
        </CardAccordion>

        {true ? (
          <DataDropDown>
            <ContainerBorder>
              <FormCreditCard>
                <Form>
                  <FormFull>
                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="Nome do titular do cartão"
                      onFocus={handleInputFocus}
                      error={null}
                      {...register("card_holder_name")}
                      isFull
                    />
                  </FormFull>

                  <FormFull>
                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="Número do cartão"
                      {...register("card_number")}
                      onFocus={() => handleInputFocus("number")}
                      error={null}
                      isFull
                    />
                  </FormFull>

                  <FormFull>
                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="Validade"
                      {...register("card_expiration_date")}
                      onFocus={handleInputFocus}
                      error={null}
                    />

                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="CVV"
                      {...register("card_cvv")}
                      error={null}
                      onFocus={() => handleInputFocus("cvc")}
                    />
                  </FormFull>

                  <FormFull>
                    <ContainerInput>
                      <FormLabel>
                        {/* {value === "1" ? <h2>Nome</h2> : <h2>Razão social</h2>} */}
                        <h2>Opções de parcelamento</h2>
                      </FormLabel>

                      {/* <input
                className="w-100"
                type="text"
                // {...register("name")}
                // defaultValue={data?.name}
                // disabled={!isEditing}
              /> */}

                      <SelectComponent />
                    </ContainerInput>
                  </FormFull>
                </Form>

                <ContainerCard>
                  {/* <CreditCardStyled /> */}

                  <Cards
                    number={watch("card_number")}
                    name={watch("card_holder_name")}
                    expiry={watch("card_expiration_date")}
                    cvc={watch("card_cvv")}
                    focused={focus}
                  />
                </ContainerCard>
              </FormCreditCard>
            </ContainerBorder>
          </DataDropDown>
        ) : null}
      </div>

      <div className="container-card">
        <CardAccordion
          active
          // active={ticket.isOpen}
          // onClick={() => handleOpenTicketEvent(ticket.id)}
        >
          <div className="content">
            <div className="image">
              <img
                className="card"
                src={card_icon}
                alt="Logo"
                width={25}
                height={22.5}
              />
            </div>

            <div className="texts">
              <h2>Cartão #2</h2>
            </div>
          </div>

          <GoChevronUp size={16} color={Colors.secondary50} />
        </CardAccordion>

        {true ? (
          <DataDropDown>
            <ContainerBorder>
              <FormCreditCard>
                <Form>
                  <FormFull>
                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="Nome do titular do cartão"
                      onFocus={handleInputFocus}
                      error={null}
                      {...register("card_holder_name")}
                      isFull
                    />
                  </FormFull>

                  <FormFull>
                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="Número do cartão"
                      {...register("card_number")}
                      onFocus={() => handleInputFocus("number")}
                      error={null}
                      isFull
                    />
                  </FormFull>

                  <FormFull>
                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="Validade"
                      {...register("card_expiration_date")}
                      onFocus={handleInputFocus}
                      error={null}
                    />

                    <InputForm
                      icon={<RiUserLine size={16} color={Colors.secondary40} />}
                      label="CVV"
                      {...register("card_cvv")}
                      error={null}
                      onFocus={() => handleInputFocus("cvc")}
                    />
                  </FormFull>

                  <FormFull>
                    <ContainerInput>
                      <FormLabel>
                        {/* {value === "1" ? <h2>Nome</h2> : <h2>Razão social</h2>} */}
                        <h2>Opções de parcelamento</h2>
                      </FormLabel>

                      {/* <input
                className="w-100"
                type="text"
                // {...register("name")}
                // defaultValue={data?.name}
                // disabled={!isEditing}
              /> */}

                      <SelectComponent />
                    </ContainerInput>
                  </FormFull>
                </Form>

                <ContainerCard>
                  {/* <CreditCardStyled /> */}

                  <Cards
                    number={watch("card_number")}
                    name={watch("card_holder_name")}
                    expiry={watch("card_expiration_date")}
                    cvc={watch("card_cvv")}
                    focused={focus}
                  />
                </ContainerCard>
              </FormCreditCard>
            </ContainerBorder>
          </DataDropDown>
        ) : null}
      </div>
    </Container>
  );
}
