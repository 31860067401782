import { Statistic } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

const { Countdown } = Statistic;

export const Container = styled.div`
  width: 100%;
  background: ${Colors.white};
  border: 1px solid ${Colors.black10};
  border-radius: 0.65rem;
  margin-top: 1.78rem;
  padding: 1.4rem 0px;
`;

export const Title = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.12rem;
  text-align: center;
  color: ${Colors.secondary100};

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: ${Colors.secondary100};
  }
`;

export const SubTitle = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.03rem;
  text-align: center;
  color: ${Colors.secondary80};
  margin-top: 0.56rem;

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-align: center;
    color: ${Colors.secondary100};
    margin-top: 4px;
  }
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.4rem 0.93rem 1.03rem 1.4rem;
  background: ${Colors.border80};
  margin-top: 1.4rem;

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;

    svg {
      max-width: 19px;
      max-height: 19px;
      min-width: 19px;
      min-height: 19px;
      margin-top: 2px;

      @media (max-width: 720px) {
        max-width: 9px !important;
        max-height: 9px !important;
        min-width: 9px !important;
        min-height: 9px !important;
        margin-top: 3px;
      }
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.93rem;
      color: ${Colors.black90};
      margin-left: 0.56rem;
      margin-bottom: 0px;

      @media (max-width: 720px) {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        margin-left: 3px;
      }
    }
  }
`;

export const ContainerItems = styled.div`
  height: 100%;
  width: 100%;

  padding: 1.96rem 1.4rem 0px 1.5rem;

  @media (max-width: 720px) {
    padding: 12px 1.4rem 0px 1.5rem;
  }
`;

export const TitleLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.93rem;
  color: ${Colors.secondary100};

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    margin-top: 12px;
  }
`;

export const ContainerCodePix = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;

  @media (max-width: 720px) {
    flex-direction: column;
    margin-top: 12px;
  }

  input {
    width: 100%;
    height: 2.81rem;
    background: ${Colors.white};
    border: 2px solid ${Colors.secondary20};
    border-radius: 0.37rem;
    padding-left: 0.93rem;
    margin-right: 0.46rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.secondary70};

    @media (max-width: 720px) {
      width: 100%;
      height: 31px;
      margin-right: 0px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

export const TitleQrCode = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.93rem;
  text-align: center;
  color: ${Colors.secondary100};
  margin-top: 1.4rem;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ContainerBoxQrCode = styled.div`
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const BoxQrCode = styled.div`
  width: 10.31rem;
  height: 10.31rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.37rem;

  img {
    width: 8.43rem;
    height: 8.48rem;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  min-width: 9rem;
  height: 2.81rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 0.65rem;

  svg {
    width: 24px;
    height: 24px;

    @media (max-width: 720px) {
      width: 9px;
      height: 10px;
    }
  }

  @media (max-width: 720px) {
    width: 100%;
    margin-top: 10px;
    height: 31px;
    border-radius: 6px;
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    color: ${Colors.white};
    margin-bottom: 0px;
    margin-left: 10px;

    @media (max-width: 720px) {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      margin-left: 11px;
    }
  }
`;

export const ContainerSuccessfullPayment = styled.div`
  width: 100%;
  height: 2.43rem;
  background: ${Colors.success}30;
  border-radius: 0.23rem;
  margin-top: 1.4rem;

  @media (max-width: 720px) {
    border-radius: 4px;
    height: 26px;
    margin-top: 20px;
  }

  img {
    height: 21px;
    width: 21px;

    @media (max-width: 720px) {
      height: 11px;
      width: 11px;
    }
  }

  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 0.93rem;
    color: ${Colors.success};
    margin-left: 12px;

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      margin-left: 6.17px;
    }
  }
`;

export const ContainerCountdown = styled.div`
  width: 100%;
  height: 2.43rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary10}30;
  border-radius: 0.23rem;
  margin-top: 1.4rem;

  @media (max-width: 720px) {
    height: 26px;
    border-radius: 4px;
    margin-top: 20px;
  }

  img {
    margin-right: 0.6rem;

    @media (max-width: 720px) {
      height: 12px;
      width: 10px;
    }
  }
`;

export const CountdownTimer = styled(Countdown)`
  .ant-statistic-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    font-size: 1.21rem;
    color: ${Colors.black100};
    font-size: 1.21rem;

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 900;
      font-size: 11px;
      color: ${Colors.black100};
    }
  }
`;

export const ContainerFinalizatePix = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

export const ButtonChangeFormPayment = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 48%;
  height: 2.81rem;
  background: ${Colors.primary10};
  border-radius: 0.65rem;

  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 14px;
  }

  @media (max-width: 670px) {
    height: 33px;
    border-radius: 6px;
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    text-align: center;
    color: ${Colors.primary100};

    @media (max-width: 670px) {
      font-size: 12px;
    }
  }
`;

export const ButtonNextToPaymentPix = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 48%;
  height: 2.81rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 0.65rem;

  @media (max-width: 1200px) {
    width: 100%;
    box-shadow: none;
  }

  @media (max-width: 670px) {
    height: 33px;
    border-radius: 6px;
  }

  img {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-size: 0.93rem;
    font-weight: 700;
    color: ${Colors.white};
    margin-left: 0.46rem;

    @media (max-width: 670px) {
      font-size: 12px;
    }
  }
`;
