import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: ${Colors.white};
  border: 1px solid ${Colors.black10};
  border-radius: 0.65rem;
  margin-top: 1.78rem;
  padding: 1.4rem 0px;
`;

export const Title = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.12rem;
  text-align: center;
  color: ${Colors.secondary100};

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: ${Colors.secondary100};
  }
`;

export const SubTitle = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.03rem;
  text-align: center;
  color: ${Colors.secondary80};
  margin-top: 0.56rem;

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-align: center;
    color: ${Colors.secondary100};
    margin-top: 4px;
  }
`;

export const SubTitleBold = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.93rem;
  text-align: center;
  text-align: center;
  color: ${Colors.secondary100};
  margin-top: 42px;

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    margin-top: 12px;
  }
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1.4rem 0.93rem 1.03rem 1.4rem;

  background: ${Colors.border80};
  margin-top: 1.4rem;

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;

    svg {
      max-width: 19px;
      max-height: 19px;
      min-width: 19px;
      min-height: 19px;
      margin-top: 2px;

      @media (max-width: 720px) {
        max-width: 9px !important;
        max-height: 9px !important;
        min-width: 9px !important;
        min-height: 9px !important;
        margin-top: 3px;
      }
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.93rem;
      color: ${Colors.black90};
      margin-left: 0.56rem;
      margin-bottom: 0px;

      @media (max-width: 720px) {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        margin-left: 3px;
      }
    }
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.4rem;

  @media (max-width: 720px) {
    margin-top: 12px;
  }
`;

export const ButtonDownload = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 14rem;
  height: 2.81rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 0.65rem;

  @media (max-width: 720px) {
    max-width: 207px;
    width: 100%;
    height: 31px;

    border-radius: 6px;
  }

  img {
    @media (max-width: 720px) {
      width: 10px;
      height: 9px;
    }
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    color: ${Colors.white};
    margin-left: 10px;

    @media (max-width: 720px) {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
    }
  }
`;
