import { Colors } from "constants/colors";
import { RiCheckboxCircleFill } from "react-icons/ri";
import {
  ButtonDownload,
  Container,
  ContainerButton,
  ContainerDetails,
  SubTitle,
  SubTitleBold,
  Title,
} from "./styles";

import download_icon from "assets/download-icon.svg";

export function Boleto() {
  return (
    <Container>
      <Title>Como realizar o pagamento do seu boleto</Title>

      <SubTitle>Atente-se aos detalhes:</SubTitle>

      <ContainerDetails>
        <ul>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>O pagamento no boleto bancário é somente à vista.</p>
          </li>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>Pagamentos em boleto possuem uma taxa de R$1,99.</p>
          </li>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Pagamentos com boleto bancário levam até 3 dias úteis para serem
              compensados e então terem os produtos liberados.
            </p>
          </li>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Atente-se ao vencimento do boleto. Você pode pagar o boleto em
              qualquer casa lotérica até o dia do vencimento.
            </p>
          </li>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Depois do pagamento, verifique seu e-mail para receber os dados de
              acesso ao produto (verifique também a caixa de spam).
            </p>
          </li>
        </ul>
      </ContainerDetails>

      <SubTitleBold>
        Para fazer o download do seu boleto, clique no link abaixo:
      </SubTitleBold>

      <ContainerButton>
        <ButtonDownload>
          <img src={download_icon} alt="" />
          <span>Fazer download do boleto</span>
        </ButtonDownload>
      </ContainerButton>
    </Container>
  );
}
