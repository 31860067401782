import { useCallback, useMemo } from "react";

import { createContext, useState, useContext } from "react";
import { Card, PersonalInformations } from "types";

type CheckoutContextData = {
  step: number;
  allIndexs: Array<number>;
  changeStep(index: number): void;
  changeLastStep(): void;
  isFirstStep: boolean;
  isLastStep: boolean;
  personalInformationsData: PersonalInformations | null;
  changePersonalInformationsData(value: PersonalInformations | null): void;
  card: Card | null;
  changeCard(card: Card | null): void;
  methodPayment: "card" | "pix" | "boleto" | "two-cards";
  changeMethodPayment(
    methodPayment: "card" | "pix" | "boleto" | "two-cards"
  ): void;
};

const CheckoutContext = createContext<CheckoutContextData | null>(null);

const CheckoutProvider = ({ children }: { children: JSX.Element }) => {
  const [step, setStep] = useState(0);
  const [allIndexs, setAllIndexs] = useState([0]);

  const [personalInformations, setPersonalInformations] =
    useState<PersonalInformations | null>(null);

  const [card, setCard] = useState<Card | null>(null);

  const [methodPayment, setMethodPayment] = useState<
    "card" | "pix" | "boleto" | "two-cards"
  >("card");

  const changeMethodPayment = useCallback(
    (methodPayment: "card" | "pix" | "boleto" | "two-cards") => {
      setMethodPayment(methodPayment);
    },
    []
  );

  const changeStep = useCallback((index: number) => {
    if (setStep) {
      setStep(index);
    }

    setAllIndexs((all) => [...all, index]);
  }, []);

  const changeLastStep = useCallback(() => {
    setStep((lastStep) => lastStep - 1);

    setAllIndexs((arr) => arr.slice(0, arr.length - 1));
  }, []);

  const resetSteps = useCallback(() => {
    setStep(0);

    setAllIndexs([0]);
  }, []);

  const changePersonalInformationsData = useCallback(
    (personalInformations: PersonalInformations) => {
      setPersonalInformations(personalInformations);
    },
    []
  );

  const changeCard = useCallback((card: Card) => {
    setCard(card);
  }, []);

  const isFirstStep = step === 0;
  const isLastStep = step === 2;

  const value = useMemo(
    () => ({
      step,
      allIndexs,
      changeStep,
      changeLastStep,
      isFirstStep,
      isLastStep,
      resetSteps,
      personalInformationsData: personalInformations,
      changePersonalInformationsData,
      card,
      changeCard,
      methodPayment,
      changeMethodPayment,
    }),
    [
      step,
      allIndexs,
      changeStep,
      changeLastStep,
      isFirstStep,
      isLastStep,
      resetSteps,
      personalInformations,
      changePersonalInformationsData,
      card,
      changeCard,
      methodPayment,
      changeMethodPayment,
    ]
  );

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

function useCheckout(): CheckoutContextData {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error(`useCheckout must be used within a CheckoutProvider`);
  }

  return context;
}

export { CheckoutProvider, useCheckout };
