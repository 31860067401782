import { useState } from "react";

import { Radio, RadioChangeEvent } from "antd";

import {
  ButtonLast,
  ButtonNext,
  CardMain,
  Container,
  ContainerButtons,
  ContainerButtonsMobile,
  ContainerFormsPayment,
  ContainerItems,
  ContainerItemsAllButtons,
  ContainerWarnings,
  Divider,
  Title,
  TitleQuestion,
} from "./styles";

import { HeaderCard } from "components/HeaderCard";

import { Boleto } from "./Boleto";
import { PIX } from "./PIX";
import { PaymentWithTwoCreditCards } from "./PaymentWithTwoCreditCard";
import { CreditCard } from "./CreditCard";
import { Courtesy } from "./Courtesy";

import { LinkForBackEvent } from "components/LinkForBackEvent";

import { useCheckout } from "contexts/CheckoutContext";
import { AsideInformations } from "components/AsideInformations";

import check_white_icon from "assets/check-white-icon.svg";

interface PaymentProps {
  isCourtesy?: boolean;
}

export function Payment({ isCourtesy }: PaymentProps) {
  const [value, setValue] = useState(isCourtesy ? 5 : 1);

  const { isFirstStep, isLastStep, changeStep } = useCheckout();

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <Container>
      <LinkForBackEvent />

      <ContainerItems>
        <CardMain>
          <HeaderCard title="Pagamento" />

          <div className="content-border">
            <Title>Forma de pagamento</Title>

            <TitleQuestion>Qual será a forma de pagamento?</TitleQuestion>

            <ContainerFormsPayment isCourtesy={isCourtesy}>
              <Radio.Group
                className="group"
                onChange={onChange}
                value={value}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: isCourtesy ? "center" : "space-between",
                }}
              >
                {isCourtesy ? <Radio value={5}>Cortesia</Radio> : null}

                {!isCourtesy ? (
                  <>
                    <Radio className="radio" value={1}>
                      Boleto
                    </Radio>
                    <Radio className="radio" value={2}>
                      PIX
                    </Radio>
                    <Radio className="radio" value={3}>
                      Cartão de crédito
                    </Radio>
                    <Radio value={4}>Pagamento em 2 cartões</Radio>{" "}
                  </>
                ) : null}
              </Radio.Group>
            </ContainerFormsPayment>

            {value === 1 ? <Boleto /> : null}

            {value === 2 ? <PIX /> : null}

            {value === 3 ? <CreditCard /> : null}

            {value === 4 ? <PaymentWithTwoCreditCards /> : null}

            {value === 5 ? <Courtesy /> : null}

            <Divider />

            <ContainerWarnings>
              <hr />

              <div className="content">
                <div className="row">
                  <h3>Avisos</h3>
                </div>

                <ul>
                  <li>
                    Pagamento parcelado com acréscimo de{" "}
                    <strong>2.92% a.m.</strong>
                  </li>

                  <li>
                    Ao finalizar a compra, você concorda com a nossa{" "}
                    <a href="#politics">Política de Privacidade.</a>
                  </li>
                </ul>
              </div>
            </ContainerWarnings>
          </div>

          <ContainerButtonsMobile>
            <ButtonLast
              type="button"
              disabled={isFirstStep}
              onClick={() => changeStep(0)}
              style={{ cursor: isFirstStep ? "not-allowed" : "pointer" }}
            >
              <span>Voltar</span>
            </ButtonLast>

            <ButtonNext
              type="button"
              onClick={() => changeStep(2)}
              disabled={isLastStep}
              style={{ cursor: isLastStep ? "not-allowed" : "pointer" }}
            >
              <span>Prosseguir</span>
            </ButtonNext>
          </ContainerButtonsMobile>
        </CardMain>

        <AsideInformations />
      </ContainerItems>

      <ContainerItemsAllButtons>
        <ContainerButtons>
          <ButtonLast
            type="button"
            disabled={isFirstStep}
            onClick={() => changeStep(0)}
            style={{ cursor: isFirstStep ? "not-allowed" : "pointer" }}
          >
            <span>Voltar</span>
          </ButtonLast>

          <ButtonNext
            type="button"
            onClick={() => changeStep(2)}
            disabled={isLastStep}
            style={{ cursor: isLastStep ? "not-allowed" : "pointer" }}
          >
            <img src={check_white_icon} alt="" />
            <span>Finalizar</span>
          </ButtonNext>
        </ContainerButtons>
      </ContainerItemsAllButtons>
    </Container>
  );
}
