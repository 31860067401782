import { Container, Title, Badge } from "./styles";

import shield from "assets/shield.svg";

interface HeaderCardProps {
  title: string;
}

export function HeaderCard({ title }: HeaderCardProps) {
  return (
    <Container>
      <Title>{title}</Title>

      <Badge>
        <img src={shield} className="icon" alt="" />
        <span>Compra 100% segura!</span>
      </Badge>
    </Container>
  );
}
