import styled from "styled-components";
import { Colors } from "../../constants/colors";

import { Statistic } from "antd";

const { Countdown } = Statistic;

export const Container = styled.header`
  width: 100%;
  height: 6.09rem;

  position: fixed;
  z-index: 99;
  display: flex;
  background-color: ${Colors.white};
  display: flex;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0px 29px 39px rgba(0, 0, 0, 0.05);

  @media (max-width: 720px) {
    height: 64px;
  }
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.26rem 2.34rem 0px 2.34rem;

  @media (max-width: 720px) {
    padding: 17px 27px 0px 28px;
  }

  img.logo {
    @media (max-width: 1024px) {
      display: none;
    }

    width: 9.6rem;
    height: 2.62rem;
  }
`;

export const Steps = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
  }

  &:first-child {
    margin-left: 0px;
  }

  @media (max-width: 720px) {
    &:first-child {
      margin-right: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

interface StepProps {
  current?: boolean;
}

export const Step = styled.li<StepProps>`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 249px;
  margin-left: 3.75rem;

  @media (max-width: 1024px) {
    margin-left: 0px;
    margin-right: 6px;

    &:first-child {
      margin-right: -7px;
    }
  }

  div.circle {
    min-width: 1.96rem;
    min-height: 1.96rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${(props) =>
      props.current ? Colors.primary100 : Colors.secondary20};

    @media (max-width: 720px) {
      width: 20px;
      height: 20px;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: ${Colors.white};

      @media (max-width: 720px) {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: ${Colors.white};
      }
    }
  }

  div.ok {
    min-width: 1.96rem;
    min-height: 1.96rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${Colors.success};

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: ${Colors.white};
    }
  }

  div.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 18px;

    @media (max-width: 720px) {
      margin-left: 6px;

      .first {
        visibility: hidden;
      }

      .first:after {
        content: "DADOS PESSOAIS";
        visibility: visible;
        display: block;
        position: absolute;
        top: 18px;
        max-width: 10px;
      }
    }

    h2 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: ${(props) => (props.current ? "700" : "600")};
      font-size: 0.75rem;
      color: ${Colors.secondary40};
      margin-bottom: 0px;

      @media (max-width: 720px) {
        display: none;
      }
    }

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 0.93rem;
      color: ${(props) =>
        props.current ? Colors.secondary100 : Colors.secondary60};
      margin-bottom: 0px;

      @media (max-width: 720px) {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        color: ${(props) =>
          props.current ? Colors.secondary100 : Colors.secondary60};
      }
    }
  }
`;

export const ContainerCountDown = styled.div`
  width: 8.48rem;
  height: 3.43rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    display: none;
  }

  div.informations {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-right: 0.84rem;

    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.56rem;
      margin-left: 0.28rem;
      color: ${Colors.secondary40};
    }
  }
`;

export const CountDownStyled = styled.div`
  width: 8.48rem;
  height: 2.43rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${Colors.primary10}30;
  padding: 5px 18px 5px 0.98rem;
  border-radius: 0.23rem;

  img.timer {
    width: 0.89rem;
    height: 1.03rem;
    margin-right: 0.6rem;
  }
`;

export const CountdownTimer = styled(Countdown)`
  .ant-statistic-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    color: ${Colors.black100};
    font-size: 1.21rem;
  }
`;
