import { SelectProps } from "antd";

import {
  RiUserLine,
  RiContactsLine,
  RiMapPinAddLine,
  RiCommunityLine,
} from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCheckout } from "contexts/CheckoutContext";

import { HeaderCard } from "components/HeaderCard";
import { Colors } from "constants/colors";
import {
  ButtonLast,
  ButtonNext,
  CardMain,
  Container,
  ContainerButtons,
  ContainerButtonsMobile,
  ContainerInputFull,
  ContainerItems,
  ContainerItemsAllButtons,
  Divider,
  FormFull,
  FormLabel,
  Title,
} from "./styles";

import { Select as SelectComponent } from "components/Forms/Select";
import { InputForm } from "components/Forms/Input";
import { LinkForBackEvent } from "components/LinkForBackEvent";
import { AsideInformations } from "components/AsideInformations";
import { PersonalInformations as PersonalInformationsFormData } from "types";

const personalInformationsFormSchema = yup.object().shape({
  name: yup.string().required("Nome Obrigatório"),
  document: yup.string().required("Documento Obrigatório"),
  cell_phone: yup.string().required("Celular Obrigatório"),
  email: yup.string().required("Email Obrigatório"),
  confirmed_email: yup
    .string()
    .required("O Email de confirmação é obrigatório")
    .oneOf([null, yup.ref("email")], "Os emails precisam ser iguais"),
  address_zipcode: yup.string().required("Cep Obrigatório"),
  address_street: yup.string().required("Rua Obrigatória"),
  address_country: yup.string().notRequired(),
  address_district: yup.string().required("Bairro Obrigatório"),
  address_number: yup.string().required("Número Obrigatório"),
  address_state: yup.string().notRequired(),
  address_city: yup.string().required("Cidade Obrigatória"),
  address_complement: yup.string().required("Complemento Obrigatório"),
});

export function PersonalInformations() {
  const options: SelectProps["options"] = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const {
    changeStep,
    step,
    isFirstStep,
    isLastStep,
    // personalInformationsData,
    changePersonalInformationsData,
  } = useCheckout();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalInformationsFormData>({
    resolver: yupResolver(personalInformationsFormSchema),
    defaultValues: {
      name: "Joel galdino",
      document: "000.000.000-00",
      cell_phone: "(67) 972728288",
      email: "joel@gmail.com",
      confirmed_email: "joel@gmail.com",
      country: "",
      address_zipcode: "76809-277",
      address_street: "Rua p1",
      address_country: "",
      address_district: "teste",
      address_number: "2",
      address_state: "teste",
      address_city: "teste",
      address_complement: "teste",
    },
  });

  const handlePersonalInformations: SubmitHandler<
    PersonalInformationsFormData
  > = async ({ ...data }) => {
    try {
      changePersonalInformationsData({ ...data });

      changeStep(step + 1);
    } catch (err) {
      alert("Erro");
    }
  };

  return (
    <Container onSubmit={handleSubmit(handlePersonalInformations)}>
      <LinkForBackEvent />

      <ContainerItems>
        <CardMain>
          <HeaderCard title="Informações pessoais" />

          <div className="content-border">
            <Title role="contentinfo">Informações pessoais</Title>

            <FormFull>
              <InputForm
                icon={<RiUserLine size={16} color={Colors.secondary40} />}
                label="Nome"
                {...register("name")}
                error={errors?.name}
                isFull
              />
            </FormFull>

            <FormFull>
              <InputForm
                icon={<RiContactsLine size={16} color={Colors.secondary40} />}
                label="CPF ou CNPJ"
                {...register("document")}
                error={errors?.document}
                mask="cpf"
              />

              <InputForm
                icon={<BsPhone size={16} color={Colors.secondary40} />}
                label="Celular"
                {...register("cell_phone")}
                error={errors?.cell_phone}
                mask="phone"
              />
            </FormFull>

            <FormFull>
              <InputForm
                icon={<AiOutlineMail size={16} color={Colors.secondary40} />}
                label="E-mail"
                {...register("email")}
                error={errors?.email}
              />

              <InputForm
                icon={<AiOutlineMail size={16} color={Colors.secondary40} />}
                label="Confirme seu e-mail"
                {...register("confirmed_email")}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                error={errors?.confirmed_email}
              />
            </FormFull>

            <ContainerInputFull className="input-full">
              <FormLabel>
                <h2>País</h2>
              </FormLabel>

              <SelectComponent />
            </ContainerInputFull>

            <Divider />

            <Title>Endereço</Title>

            <FormFull>
              <InputForm
                icon={<RiMapPinAddLine size={16} color={Colors.secondary40} />}
                label="CEP"
                {...register("address_zipcode")}
                error={errors?.address_zipcode}
                mask="cep"
              />

              <InputForm
                icon={<RiCommunityLine size={16} color={Colors.secondary40} />}
                label="Endereço"
                {...register("address_street")}
                error={errors?.address_street}
              />
            </FormFull>

            <FormFull>
              <InputForm
                icon={<RiCommunityLine size={16} color={Colors.secondary40} />}
                label="Número"
                {...register("address_number")}
                error={errors?.address_number}
              />

              <InputForm
                icon={<RiCommunityLine size={16} color={Colors.secondary40} />}
                label="Complemento"
                {...register("address_complement")}
                error={errors?.address_complement}
              />
            </FormFull>

            <FormFull>
              <InputForm
                icon={<RiCommunityLine size={16} color={Colors.secondary40} />}
                label="Bairro"
                {...register("address_district")}
                error={errors?.address_district}
              />

              <InputForm
                icon={<RiMapPinAddLine size={16} color={Colors.secondary40} />}
                label="Cidade"
                {...register("address_city")}
                error={errors?.address_city}
              />
            </FormFull>

            <ContainerInputFull className="input-full">
              <FormLabel>
                <h2>Estado</h2>
              </FormLabel>

              <SelectComponent />
            </ContainerInputFull>
          </div>

          <ContainerButtonsMobile>
            <ButtonLast
              type="button"
              disabled={isFirstStep}
              style={{ cursor: isFirstStep ? "not-allowed" : "pointer" }}
            >
              <span>Voltar</span>
            </ButtonLast>

            <ButtonNext
              type="submit"
              disabled={isLastStep}
              style={{ cursor: isLastStep ? "not-allowed" : "pointer" }}
            >
              <span>Prosseguir</span>
            </ButtonNext>
          </ContainerButtonsMobile>
        </CardMain>

        <AsideInformations />
      </ContainerItems>

      <ContainerItemsAllButtons>
        <ContainerButtons>
          <ButtonLast
            type="button"
            disabled={isFirstStep}
            style={{ cursor: isFirstStep ? "not-allowed" : "pointer" }}
          >
            <span>Voltar</span>
          </ButtonLast>

          <ButtonNext
            type="submit"
            disabled={isLastStep}
            style={{ cursor: isLastStep ? "not-allowed" : "pointer" }}
          >
            <span>Prosseguir</span>
          </ButtonNext>
        </ContainerButtons>
      </ContainerItemsAllButtons>
    </Container>
  );
}
