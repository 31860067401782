import {
  ButtonBack,
  CardMain,
  Container,
  ContainerButton,
  ContainerDetails,
  ContainerItems,
  ContainerRigths,
  ContainerRowLogo,
  SubTitle,
  Title,
} from "./styles";

import { AsideInformations } from "components/AsideInformations";
import { LinkForBackEvent } from "components/LinkForBackEvent";
import { HeaderCard } from "components/HeaderCard";

import logo_small from "assets/logo-small.svg";
import logo_small_gray from "assets/logo-small-gray.svg";
import home_icon from "assets/home-icon.svg";
import { useCheckout } from "contexts/CheckoutContext";

export function Thanks() {
  const { changeStep } = useCheckout();

  return (
    <Container>
      <LinkForBackEvent />

      <ContainerItems>
        <CardMain>
          <HeaderCard title="Agradecimento" />

          <div className="content-border">
            <Title>Obrigado pela sua compra!</Title>

            <SubTitle>
              Este é um subtítulo da mensagem de agradecimento.
            </SubTitle>

            <ContainerDetails>
              <h2>Título da mensagem</h2>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                elementum vitae viverra scelerisque donec pellentesque. Sem
                consequat semper lobortis nisl eu venenatis elit. Scelerisque
                vestibulum mauris amet ut tristique ut neque. Purus praesent
                pulvinar aenean metus odio enim ut egestas. Enim elit at nisl
                gravida faucibus. Sollicitudin nisl, non donec hendrerit nulla
                lacus condimentum sapien a. Eu in lorem placerat cras urna,
                egestas sed et.
              </p>

              <strong>
                Para fazer o download do seus ingresso, clique no link abaixo:{" "}
                <a href="#h">www.exemplodelink.com.br </a>
              </strong>

              <p className="last">At,</p>

              <ContainerRowLogo>
                <h3>Equipe</h3>

                <img src={logo_small} alt="" />
              </ContainerRowLogo>
            </ContainerDetails>

            <ContainerRigths>
              <img src={logo_small_gray} alt="" />

              <h3>© 2022 Eventx - Todos os direitos reservados</h3>
            </ContainerRigths>
          </div>

          <ContainerButton>
            <ButtonBack onClick={() => changeStep(0)}>
              <img src={home_icon} alt="" />
              <span>Fechar e voltar para página inicial</span>
            </ButtonBack>
          </ContainerButton>
        </CardMain>

        <div className="aside">
          <AsideInformations dontRenderSummary />
        </div>
      </ContainerItems>
    </Container>
  );
}
