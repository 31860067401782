import "react-credit-cards/es/styles-compiled.css";
import "./App.css";

import { CheckoutProvider } from "contexts/CheckoutContext";
import { Checkout } from "./pages/checkout";
import GlobalStyle from "./styles/GlobalStyle";

function App() {
  return (
    <>
      <GlobalStyle />

      <CheckoutProvider>
        <Checkout />
      </CheckoutProvider>
    </>
  );
}

export default App;
