import { Colors } from "../constants/colors";
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
 // calculate sizes 60px in rem,
 // (1440 * 60) / 1920 = 45px -> 45px / 16 =  2,8125rem
 // 1920 to 1440 screen

 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Cairo', sans-serif;
  }


  body {
    background: ${Colors.white};
    font-family: 'Cairo', sans-serif;
    text-rendering: optimizeLegibility ;
    -webkit-font-smoothing: antialised;
  }

`;
