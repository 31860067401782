import { useCallback, useState } from "react";

import { RiCheckboxCircleFill } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  Container,
  ContainerCodePix,
  ContainerDetails,
  ContainerItems,
  SubTitle,
  Title,
  TitleLabel,
  Button,
  ContainerCountdown,
  TitleQrCode,
  ContainerBoxQrCode,
  BoxQrCode,
  CountdownTimer,
  ContainerFinalizatePix,
  ButtonChangeFormPayment,
  ButtonNextToPaymentPix,
} from "./styles";

import qr_code from "assets/qr-code.png";
import timer from "assets/timer.svg";
import copy_white_icon from "assets/copy-white-icon.svg";
import pix_icon from "assets/pix-icon.svg";

const deadline = Date.now() + 10 * 60 * 60 * 24 * 1 + 1000 * 30;

export function PIX() {
  const onFinish = function () {};

  const [renderPixCode, setRenderPixCode] = useState(false);

  const handleChangeToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handlePayment = useCallback(() => {
    setRenderPixCode(true);

    window.scrollTo({ top: 650, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <Title>Instruções para realizar o pagamento</Title>

      <SubTitle>Atente-se aos detalhes:</SubTitle>

      <ContainerDetails>
        <ul>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Copie o código abaixo para pagar via PIX em qualquer aplicativo
              habilitado.
            </p>
          </li>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Ou, abra o aplicativo do seu banco e escaneie o QR Code com o seu
              celular.
            </p>
          </li>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Você tem 5 minutos para fazer o pagamento, após esse tempo sua
              compra será cancelada.
            </p>
          </li>
        </ul>
      </ContainerDetails>

      {!renderPixCode ? (
        <ContainerItems>
          <ContainerFinalizatePix>
            <ButtonChangeFormPayment onClick={handleChangeToTop}>
              <span>Escolher outra forma de pagamento</span>
            </ButtonChangeFormPayment>

            <ButtonNextToPaymentPix onClick={handlePayment}>
              <img src={pix_icon} alt="pix" />
              <span>Prosseguir o pagamento com PIX</span>
            </ButtonNextToPaymentPix>
          </ContainerFinalizatePix>
        </ContainerItems>
      ) : null}

      {renderPixCode ? (
        <ContainerItems>
          <TitleLabel>Código PIX</TitleLabel>

          <ContainerCodePix>
            <input type="text" placeholder="EXEMPLODECODIGO.PIX" />

            <Button>
              <img src={copy_white_icon} alt="" />

              <span>Copiar código</span>
            </Button>
          </ContainerCodePix>

          <ContainerCountdown>
            <img src={timer} alt="" className="timer" />

            <CountdownTimer value={deadline} onFinish={onFinish} />
          </ContainerCountdown>

          <TitleQrCode>Ou, escaneie o QR Code</TitleQrCode>

          <ContainerBoxQrCode>
            <BoxQrCode>
              <img src={qr_code} alt="" />
            </BoxQrCode>
          </ContainerBoxQrCode>
        </ContainerItems>
      ) : null}
    </Container>
  );
}
