import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const FormCreditCard = styled.div`
  width: 100%;
  margin-top: 1.78rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Form = styled.div`
  width: 48%;

  @media (max-width: 720px) {
    width: 100%;
  }

  > * {
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.87rem;

  div.all-input {
    width: 94%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  }
`;

interface ContainerInputProps {
  error?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    min-height: 40px;
  }

  input {
    font-family: "Poppins";
    width: 96%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    font-family: "Poppins";
    margin-top: 0.46rem;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 0.93rem;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;
      display: flex;
      align-items: center;
      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerCard = styled.div`
  width: 48%;

  @media (max-width: 720px) {
    display: none;
  }
`;
