import { Colors } from "constants/colors";
import styled from "styled-components";

import { Statistic } from "antd";

const { Countdown } = Statistic;

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8.62rem;
  flex-direction: column;
  align-items: center;

  @media (max-width: 720px) {
    margin-top: 88px;
  }
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.white};
  padding-right: 2.34rem;
  padding-left: 2.34rem;

  @media (max-width: 720px) {
    padding-right: 28px;
    padding-left: 28px;
  }

  div.texts {
    img {
      width: 17px;
      height: 16px;

      @media (max-width: 720px) {
        width: 10px;
        height: 10px;
      }
    }

    a {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-decoration-line: underline;
      margin-left: 14px;
      margin-bottom: 0px;
      color: ${Colors.primary100};

      @media (max-width: 720px) {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 160%;
        text-decoration-line: underline;
        color: ${Colors.primary100};
        margin-left: 8px;
      }
    }
  }
`;

export const CountDownStyled = styled.div`
  width: 8.48rem;
  height: 2.43rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${Colors.primary10}30;
  padding: 5px 18px 5px 0.98rem;
  border-radius: 0.23rem;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 720px) {
    padding: 6px 6px 4px 7px;
    width: 77px;
    height: 26px;
    border-radius: 4px;
  }

  img.timer {
    width: 0.89rem;
    height: 1.03rem;
    margin-right: 0.6rem;

    @media (max-width: 720px) {
      width: 10;
      height: 12px;
      margin-right: 6.75px;
    }
  }
`;

export const CountdownTimer = styled(Countdown)`
  .ant-statistic-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    color: ${Colors.black100};
    font-size: 1.21rem;

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      display: flex;
      align-items: center;
      color: ${Colors.black100};
    }
  }
`;
