import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.aside``;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 30px;
  width: 22.35rem;
  background: ${Colors.white};
  border: 1px solid ${Colors.border10};
  box-shadow: 0px 0px 30px rgba(18, 25, 34, 0.05);
  border-radius: 20px;
  margin-bottom: 1.87rem;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 720px) {
    padding: 10px 12px;
    height: 100%;
    border-radius: 10px;
  }
`;

export const CardDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 30px;
  width: 22.35rem;
  background: ${Colors.white};
  border: 1px solid ${Colors.border10};
  box-shadow: 0px 0px 30px rgba(18, 25, 34, 0.05);
  border-radius: 20px;
  margin-bottom: 1.87rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderSmallDashed = styled.div`
  width: 100%;
  height: 2.67rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px dashed ${Colors.black20};

  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }

  @media (max-width: 720px) {
    padding-bottom: 9px;
  }
`;

export const TitleSmall = styled.h5`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.03rem;
  color: ${Colors.secondary100};
  margin-bottom: 0px;

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: ${Colors.secondary100};
  }
`;

export const Badge = styled.div`
  width: 12rem;
  height: 2.81rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(251, 229, 237, 0.3);
  border-radius: 0.65rem;
  padding-left: 0.84rem;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 720px) {
    width: 111px;
    height: 26px;
    border-radius: 4px;
    padding-left: 6.5px;
  }

  img.icon {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 720px) {
      width: 9px;
      height: 11px;
    }
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 900;
    font-size: 0.93rem;
    padding-left: 0.46rem;
    color: ${Colors.primary100};

    @media (max-width: 720px) {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900;
      font-size: 9px;
      color: ${Colors.primary100};
      padding-left: 5.5px;
    }
  }
`;

export const ListTickets = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 0px;
`;

export const TicketCard = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4.87rem;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0px;
  }

  padding: 0.56rem;
  background: ${Colors.white};
  border: 1px solid ${Colors.black10};
  border-radius: 0.46rem;

  img.image {
    width: 3.65rem;
    height: 3.75rem;
    border-radius: 0.23rem;
  }

  div.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0.56rem;

    div.row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        color: ${Colors.secondary100};
      }

      h5 {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 600;
        font-size: 0.65rem;
        color: ${Colors.black100};
      }

      small {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 0.65rem;
        color: ${Colors.black100};
      }

      strong {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        color: ${Colors.black100};
      }
    }
  }
`;

export const CardPrices = styled.div`
  width: 100%;
  height: 8.57rem;
  margin-top: 1.4rem;
  padding: 0.75rem 0.56rem 0.84rem 0.56rem;
  background: ${Colors.white};
  border: 1px solid ${Colors.black10};
  border-radius: 0.46rem;

  @media (max-width: 720px) {
    margin-top: 16px;
  }

  div.row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      color: ${Colors.black70};
    }

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      text-align: right;
      color: ${Colors.black100};
    }
  }

  div.border {
    width: 100%;
    border-top: 1px solid ${Colors.secondary10};
    margin-top: 1.12rem;
    margin-bottom: 1.45rem;
  }
`;

export const EventBackground = styled.div`
  width: 100%;
  height: 5.95rem;
  margin-top: 1.4rem;
  background: rgba(80, 64, 121, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 0.46rem;
`;

export const ListPills = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 0.7rem;
`;

export const Pill = styled.li`
  list-style: none;
  margin-right: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.14rem 0.42rem;

  min-width: 2.85rem;
  height: 1.17rem;
  background: ${Colors.secondary10};
  border-radius: 4.68rem;

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 0.56rem;
    color: ${Colors.secondary100};
  }
`;

export const BorderDescription = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0.75rem 0.56rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.46rem;
  margin-top: 1.4rem;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 0.93rem;
    color: ${Colors.secondary100};
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    color: ${Colors.black70};
    margin-top: 0.56rem;
  }
`;
