import { useCheckout } from "contexts/CheckoutContext";
import { useCallback } from "react";

import { Header } from "../../components/Header";
import { Payment } from "./steps/Payment";
import { PersonalInformations } from "./steps/PersonalInformations";
import { Thanks } from "./steps/Thanks";
import { Container } from "./styles";

export function Checkout() {
  const { step } = useCheckout();

  const renderPageStep = useCallback((index: number) => {
    switch (index) {
      case 0:
        return <PersonalInformations />;
      case 1:
        return <Payment />;
      case 2:
        return <Thanks />;

      default:
        return <PersonalInformations />;
    }
  }, []);

  return (
    <>
      <Header />

      <Container>{renderPageStep(step)}</Container>
    </>
  );
}
