import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  div.container-card {
    @media (max-width: 720px) {
      margin-top: 30px;
    }
  }
`;

interface CardAccordionProps {
  active?: boolean;
}

export const CardAccordion = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;
  height: 3.98rem;
  background: ${Colors.black5};
  border: 1px solid ${Colors.black10};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 0.65rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.78rem;
  z-index: 2;
  border: 1px solid ${Colors.border50};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  padding: 0.89rem 1.92rem 1.17rem 1.96rem;

  @media (max-width: 720px) {
    height: 38px;
    padding: 0px 16px 3px 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 5px;

    img.card {
      width: 20px;
      height: 18px;
    }

    svg {
      width: 20px;
      height: 18px;
    }
  }

  div.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    div.image {
      margin-top: 2px;

      @media (max-width: 720px) {
        margin-top: 0px;
      }
    }

    div.texts {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 23px;

      @media (max-width: 720px) {
        margin-left: 12px;
      }

      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 0.93rem;
        color: ${Colors.secondary100};
        margin-bottom: 0px;

        @media (max-width: 720px) {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: ${Colors.secondary100};
        }
      }

      p {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;
        color: ${Colors.secondary40};

        @media (max-width: 800px) {
          font-weight: bold;
          font-size: 10px;
          line-height: 19px;

          color: ${Colors.secondary40};
        }
      }
    }
  }
`;

export const DataDropDown = styled.div`
  display: block;
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: -35px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;
      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  min-height: 3.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0.65rem;
  padding-right: 20px;

  div.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    padding-left: 30px;
    width: 100%;

    div.column {
      display: flex;
      flex-direction: column;

      h2 {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        line-height: 160%;
        color: ${Colors.black80};
      }

      p {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 0.84rem;
        margin-bottom: 0px;
        color: ${Colors.black70};
      }
    }
  }

  div.container-image {
    margin-top: 40px;

    @media (max-width: 1200px) {
      padding-left: 10px;
    }

    img {
      border-radius: 10px;
    }
  }
`;

export const FormCreditCard = styled.div`
  width: 100%;
  margin-top: 3.78rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Form = styled.div`
  width: 48%;
  padding-left: 16px;

  @media (max-width: 720px) {
    width: 100%;
  }

  > * {
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.87rem;

  div.all-input {
    width: 94%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  }
`;

interface ContainerInputProps {
  error?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  min-height: 110px;

  @media (max-width: 800px) {
    min-height: 40px;
  }

  input {
    font-family: "Poppins";
    width: 96%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    font-family: "Poppins";
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 0.93rem;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;
      display: flex;
      align-items: center;
      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerCard = styled.div`
  width: 48%;

  @media (max-width: 720px) {
    display: none;
  }
`;
