import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px dashed ${Colors.black20};

  @media (max-width: 720px) {
    height: 33px;
  }
`;

export const Title = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.21rem;
  color: ${Colors.secondary100};
  margin-bottom: 0px;

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: ${Colors.secondary100};
  }
`;

export const Badge = styled.div`
  width: 12rem;
  height: 2.81rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(251, 229, 237, 0.3);
  border-radius: 0.65rem;
  padding-left: 0.84rem;

  @media (max-width: 1024px) {
    display: none;
  }

  img.icon {
    width: 1.12rem;
    height: 1.12rem;
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    padding-left: 0.46rem;
    color: ${Colors.primary100};
  }
`;
