import {
  Container,
  ContainerItems,
  CountDownStyled,
  CountdownTimer,
} from "./styles";

import back_icon from "assets/back-icon.svg";

import timer from "../../assets/timer.svg";

const deadline = Date.now() + 10 * 60 * 60 * 24 * 1 + 1000 * 30;

export function LinkForBackEvent() {
  const onFinish = function () {};

  return (
    <Container>
      <ContainerItems>
        <div className="texts">
          <img src={back_icon} alt="" />
          <a href="#a">Voltar para página do evento</a>
        </div>

        <CountDownStyled>
          <img src={timer} alt="" className="timer" />

          <CountdownTimer value={deadline} onFinish={onFinish} />
        </CountDownStyled>
      </ContainerItems>
    </Container>
  );
}
