import { useState, useEffect } from "react";

import { BiCheck } from "react-icons/bi";

import {
  Container,
  ContainerCountDown,
  ContainerItems,
  CountDownStyled,
  CountdownTimer,
  Step,
  Steps,
} from "./styles";

import { useCheckout } from "contexts/CheckoutContext";
import { Colors } from "constants/colors";

import logo from "../../assets/logo.svg";
import timer from "../../assets/timer.svg";

const deadline = Date.now() + 10 * 60 * 60 * 24 * 1 + 1000 * 30;

export function Header() {
  const { step } = useCheckout();

  const [navSteps, setNavSteps] = useState([
    {
      title: "PASSO 1",
      description: "INFORMAÇÕES PESSOAIS",
      current: false,
      completed: false,
    },
    {
      title: "PASSO 2",
      description: "PAGAMENTO",
      current: false,
      completed: false,
    },
    {
      title: "PASSO 3",
      description: "AGRADECIMENTO",
      current: false,
      completed: false,
    },
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setNavSteps((prev) => {
      return prev.map((item, index) => {
        if (index === step) {
          return { ...item, current: true, completed: false };
        }

        return {
          ...item,
          current: false,
          completed: step > index ? true : false,
        };
      });
    });
  }, [step]);

  const onFinish = function () {};

  return (
    <Container>
      <ContainerItems>
        <img
          src={logo}
          alt="logo"
          className="logo"
          width={182.14}
          height={126.06}
        />

        <Steps>
          {navSteps.map((step, index) => (
            <Step current={step.current}>
              {step.completed ? (
                <div className="ok">
                  <BiCheck color={Colors.white} size={22} />
                </div>
              ) : null}

              {!step.completed ? (
                <div className="circle">
                  <span>{index + 1}</span>
                </div>
              ) : null}

              <div className="column">
                <h2>{step.title}</h2>

                <h3 className={index === 0 ? "first" : ""}>
                  {step.description}
                </h3>
              </div>
            </Step>
          ))}
        </Steps>

        <ContainerCountDown>
          <CountDownStyled>
            <img src={timer} alt="" className="timer" />

            <CountdownTimer value={deadline} onFinish={onFinish} />
          </CountDownStyled>

          <div className="informations">
            <span>HR</span>
            <span>MIN</span>
            <span>SEG</span>
          </div>
        </ContainerCountDown>
      </ContainerItems>
    </Container>
  );
}
