import styled from "styled-components";
import { Colors } from "../../../../constants/colors";

export const Container = styled.form`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.white};
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin-top: 1.87rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${Colors.white};
  padding-right: 2.34rem;
  padding-left: 2.34rem;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media (max-width: 720px) {
    padding-right: 28px;
    padding-left: 28px;
  }
`;

export const ContainerItemsAllButtons = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin-top: 4.68rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${Colors.white};
  padding-right: 2.34rem;
  padding-left: 2.34rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const CardMain = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 52.9rem;
  width: 100%;

  margin-right: 20px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border10};
  box-shadow: 0px 0px 30px rgba(18, 25, 34, 0.05);
  border-radius: 20px;
  padding: 50px;

  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: 720px) {
    padding: 10px 12px;
    border-radius: 10px;
  }

  div.content-border {
    width: 100%;
    margin-top: 50px;
    padding: 50px 36px 40px 36px;
    background: ${Colors.white};
    border: 1px solid ${Colors.black10};
    border-radius: 20px;

    @media (max-width: 720px) {
      margin-top: 18px;
      padding: 10px;
      border-radius: 6px;
    }
  }
`;

export const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.21rem;
  margin-bottom: 1.78rem;
  color: ${Colors.secondary100};

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: ${Colors.secondary100};
  }
`;

export const Divider = styled.div`
  height: 1.87rem;
  margin-bottom: 1.87rem;
  border-bottom: 1px solid #e9e7ee;
`;

interface ContainerInputProps {
  error?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    font-family: "Poppins";
    /* margin-left: 13px; */
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 0.93rem;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.87rem;

  div.all-input {
    width: 94%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 0px;

    div.all-input {
      width: 100%;
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerButtonsMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ButtonLast = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 18.23rem;
  height: 3.84rem;
  border-radius: 0.65rem;
  background: ${Colors.black10};

  @media (max-width: 670px) {
    width: 132px;
    height: 38px;
    border-radius: 8px;
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    text-align: center;
    color: ${Colors.black30};

    @media (max-width: 670px) {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
    }
  }
`;

export const ButtonNext = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 18.23rem;
  height: 3.84rem;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  @media (max-width: 670px) {
    width: 132px;
    height: 38px;
    border-radius: 8px;
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    text-align: center;
    color: ${Colors.white};

    @media (max-width: 670px) {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
    }
  }
`;
