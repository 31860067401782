import { Select } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-right: 0.51rem;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0.45rem;

    height: 32px;
    border-radius: 6px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 0.93rem;
    color: ${Colors.secondary40};
  }
`;
