import { useState } from "react";

import Cards, { Focused } from "react-credit-cards";

import { InputForm } from "components/Forms/Input";
import { Colors } from "constants/colors";
import { RiUserLine } from "react-icons/ri";
import {
  Container,
  ContainerCard,
  ContainerInput,
  Form,
  FormCreditCard,
  FormFull,
  FormLabel,
} from "./styles";

import { Select as SelectComponent } from "components/Forms/Select";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type CardFormData = {
  card_holder_name: string;
  card_number: string;
  card_expiration_date: string;
  card_cvv: string;
};

const cardFormSchema = yup.object().shape({
  card_number: yup.string().required("Numero Obrigatorio"),
  card_holder_name: yup.string().required("Nome obrigatório"),
  card_expiration_date: yup.string().required("Data obrigatória"),
  card_cvv: yup.string().required("Cvv obrigatório"),
});

export function CreditCard() {
  const [focus, setFocus] = useState<Focused | undefined>("number");

  const { register, handleSubmit, watch } = useForm({
    resolver: yupResolver(cardFormSchema),
    defaultValues: {
      card_holder_name: "",
      card_number: "",
      card_expiration_date: "",
      card_cvv: "",
      // card_cvv: 690,
    },
  });

  const handleInputFocus = (e: any): void => {
    setFocus(e);
  };

  const handleCreditCard: SubmitHandler<CardFormData> = async ({ ...data }) => {
    try {
    } catch (err) {
      alert("Erro");
    }
  };

  return (
    <Container>
      <FormCreditCard>
        <Form onSubmit={handleSubmit(handleCreditCard)}>
          <FormFull>
            <InputForm
              icon={<RiUserLine size={16} color={Colors.secondary40} />}
              label="Nome do titular do cartão"
              onFocus={handleInputFocus}
              error={null}
              {...register("card_holder_name")}
              isFull
            />
          </FormFull>

          <FormFull>
            <InputForm
              icon={<RiUserLine size={16} color={Colors.secondary40} />}
              label="Número do cartão"
              {...register("card_number")}
              onFocus={() => handleInputFocus("number")}
              error={null}
              isFull
            />
          </FormFull>

          <FormFull>
            <InputForm
              icon={<RiUserLine size={16} color={Colors.secondary40} />}
              label="Validade"
              {...register("card_expiration_date")}
              onFocus={handleInputFocus}
              error={null}
            />

            <InputForm
              icon={<RiUserLine size={16} color={Colors.secondary40} />}
              label="CVV"
              {...register("card_cvv")}
              error={null}
              onFocus={() => handleInputFocus("cvc")}
            />
          </FormFull>

          <FormFull>
            <ContainerInput>
              <FormLabel>
                {/* {value === "1" ? <h2>Nome</h2> : <h2>Razão social</h2>} */}
                <h2>Opções de parcelamento</h2>
              </FormLabel>

              {/* <input
                className="w-100"
                type="text"
                // {...register("name")}
                // defaultValue={data?.name}
                // disabled={!isEditing}
              /> */}

              <SelectComponent />
            </ContainerInput>
          </FormFull>
        </Form>

        <ContainerCard>
          <Cards
            number={watch("card_number")}
            name={watch("card_holder_name")}
            expiry={watch("card_expiration_date")}
            cvc={watch("card_cvv")}
            focused={focus}
          />
        </ContainerCard>
      </FormCreditCard>
    </Container>
  );
}
