import styled from "styled-components";
import { Colors } from "../../../../constants/colors";

export const Container = styled.header`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.white};
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin-top: 1.87rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${Colors.white};
  padding-right: 2.34rem;
  padding-left: 2.34rem;

  @media (max-width: 720px) {
    padding-right: 28px;
    padding-left: 28px;
  }

  div.aside {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const CardMain = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 52.9rem;

  width: 100%;
  height: 100%;
  margin-right: 0.93rem;
  background: ${Colors.white};
  border: 1px solid ${Colors.border10};
  box-shadow: 0px 0px 30px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;
  padding: 2.34rem;

  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 720px) {
    padding: 12px 12px 20px 12px;
  }

  div.content-border {
    width: 100%;
    margin-top: 2.34rem;
    padding: 2.34rem 0px 1.87rem 0px;
    background: ${Colors.white};

    border: 1px solid ${Colors.black10};
    border-radius: 0.93rem;

    @media (max-width: 720px) {
      padding: 12px 0px 13px 0px;
    }
  }
`;

export const Title = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.12rem;
  text-align: center;
  color: ${Colors.secondary100};

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }
`;

export const SubTitle = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.03rem;
  text-align: center;
  color: ${Colors.secondary80};
  margin-top: 0.56rem;

  @media (max-width: 720px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    margin-top: 4px;
  }
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1.4rem 0.93rem 1.03rem 1.4rem;

  background: ${Colors.border80};
  margin-top: 1.4rem;

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    color: ${Colors.black90};
    margin-bottom: 0px;

    @media (max-width: 720px) {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
    }
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.black90};
    margin-top: 24px;
    margin-bottom: 0px;

    @media (max-width: 720px) {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      margin-top: 8px;
    }
  }

  strong {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.93rem;
    color: ${Colors.black90};
    margin-top: 24px;

    @media (max-width: 720px) {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      margin-top: 8px;
    }

    a {
      color: ${Colors.primary100};
      text-decoration: underline;
    }
  }

  p.last {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.black90};
    margin-top: 1.12rem;

    @media (max-width: 720px) {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      margin-top: 8px;
    }
  }
`;

export const ContainerRowLogo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.31rem;

  @media (max-width: 720px) {
    margin-top: 9px;
    align-items: center;
    justify-content: center;
  }

  h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.black90};
    margin-right: 0.79rem;
    margin-bottom: 0px;

    @media (max-width: 720px) {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      margin-right: 6px;
    }
  }

  img {
    width: 6.79rem;
    height: 1.78rem;

    @media (max-width: 720px) {
      width: 56.76px;
      height: 15px;
    }
  }
`;

export const ContainerRigths = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 62px 30px 0px 30px;

  @media (max-width: 720px) {
    padding: 23px 30px 0px 30px;
  }

  @media (max-width: 390px) {
    flex-direction: column;
  }

  h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.black20};
    margin-bottom: 0px;

    @media (max-width: 720px) {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
    }

    @media (max-width: 390px) {
      margin-top: 2px;
    }
  }

  img {
    width: 6.79rem;
    height: 1.78rem;

    @media (max-width: 720px) {
      width: 56.76px;
      height: 15px;
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.34rem;

  @media (max-width: 720px) {
    margin-top: 40px;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 24.65rem;
  height: 3.84rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 0.65rem;

  @media (max-width: 720px) {
    width: 100%;
    height: 38px;
    border-radius: 8px;
  }

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    color: ${Colors.white};
    margin-left: 0.46rem;

    @media (max-width: 720px) {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;

      margin-left: 7px;
    }
  }

  img {
    width: 1.31rem;
    height: 1.31rem;

    @media (max-width: 720px) {
      height: 10.64px;
      width: 11.91px;
    }
  }
`;
