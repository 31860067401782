import {
  Badge,
  BorderDescription,
  Card,
  CardDescription,
  CardPrices,
  Container,
  EventBackground,
  HeaderSmallDashed,
  ListPills,
  ListTickets,
  Pill,
  TicketCard,
  TitleSmall,
} from "./styles";

import ticket_image from "assets/ticket-image.png";
import shield from "assets/shield.svg";

interface AsideInformationsProps {
  dontRenderSummary?: boolean;
}

export function AsideInformations({
  dontRenderSummary,
}: AsideInformationsProps) {
  return (
    <Container>
      {!dontRenderSummary ? (
        <Card>
          <HeaderSmallDashed>
            <TitleSmall>Resumo do pedido</TitleSmall>

            <Badge>
              <img src={shield} className="icon" alt="" />
              <span>Compra 100% segura!</span>
            </Badge>
          </HeaderSmallDashed>

          <ListTickets>
            {[0, 1].map((item) => (
              <TicketCard>
                <img src={ticket_image} className="image" alt="" />

                <div className="content">
                  <div className="row">
                    <h4>Festival indie - Edição VI</h4>

                    <h5>2x</h5>
                  </div>

                  <div className="row">
                    <small>Ingresso Premium</small>

                    <strong>R$100,00</strong>
                  </div>
                </div>
              </TicketCard>
            ))}
          </ListTickets>

          <CardPrices>
            <div className="row">
              <h2>Subtotal</h2>

              <h3>R$220,00</h3>
            </div>

            <div className="row">
              <h2>Taxas</h2>

              <h3>R$22,00</h3>
            </div>

            <div className="border" />

            <div className="row">
              <h2>Total</h2>

              <h3>R$242,00</h3>
            </div>
          </CardPrices>
        </Card>
      ) : null}

      <CardDescription>
        <HeaderSmallDashed>
          <TitleSmall>Descrição do produto</TitleSmall>
        </HeaderSmallDashed>

        <EventBackground />

        <ListPills>
          {["Música", "Show", "Ao vivo"].map((word) => (
            <Pill>
              <span>{word}</span>
            </Pill>
          ))}
        </ListPills>

        <BorderDescription>
          <h2>Descrição</h2>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
            pretium, luctus arcu dignissim neque ut ipsum. Tortor porta gravida
            lacus tempor, scelerisque. Nunc, venenatis justo, in tempus non,
            cursus. Lectus purus volutpat molestie eget fermentum eu, dolor. Ut
            cursus nisi, scelerisque posuere donec.
          </p>
        </BorderDescription>
      </CardDescription>
    </Container>
  );
}
