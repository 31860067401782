import { RiCheckboxCircleFill } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  Container,
  ContainerInputCode,
  ContainerDetails,
  ContainerItems,
  SubTitle,
  Title,
  TitleLabel,
  Button,
  ContainerButton,
} from "./styles";

import key_icon from "assets/key-icon.svg";

export function Courtesy() {
  return (
    <Container>
      <Title>Uso da cortesia</Title>

      <SubTitle>Atente-se aos detalhes:</SubTitle>

      <ContainerDetails>
        <ul>
          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Foi concebido a você o acesso à esta cortesia, não compartilhe
              este link com ninguém.
            </p>
          </li>

          <li>
            <RiCheckboxCircleFill size={18} color={Colors.primary100} />
            <p>
              Para utilizar esta cortesia, insira abaixo seu cupom para
              validação.
            </p>
          </li>
        </ul>
      </ContainerDetails>

      <ContainerItems>
        <TitleLabel>Cupom de validação</TitleLabel>

        <ContainerInputCode>
          <img src={key_icon} alt="" />

          <input type="text" placeholder="Código do cupom" />
        </ContainerInputCode>

        <ContainerButton>
          <Button>
            <span>Gerar cortesia</span>
          </Button>
        </ContainerButton>
      </ContainerItems>
    </Container>
  );
}
