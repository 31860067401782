import { BorderSelect, SelectStyled } from "./styles";

export function Select() {
  return (
    <BorderSelect>
      <SelectStyled
        bordered={false}
        size="large"
        labelInValue
        defaultValue={{ value: 0, label: "Data" }}
        // onChange={handleChangeTwo}
      >
        {/* {dataOptions.map((op) => (
        <Option key={op.value} value={op.value}>
          {op.option}
        </Option>
      ))} */}
      </SelectStyled>
    </BorderSelect>
  );
}
